.projectStatusLabel {
  width: fit-content;
  display: block;
  font-size: 16px;
  font-weight: lighter;
  padding: 5px;
  border-radius: var(--border-radius);
  background-color: var(--main-background-color-alpha);

  &[data-status="true"] {
    color: var(--color-success);
  }

  &[data-status="false"] {
    & * {
      color: var(--color-error);
    }
  }
}
