@import "../../scss/_sass_vars.scss";

@keyframes fakeChatWindowAnimation {
  0% {
    opacity: 0.1;
    transform: scale(1) translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: scale(1.2) translateX(0);
  }
}

.fakeChatWindowWrapper {
  position: absolute;
  top: 50%;
  right: 110px;
  pointer-events: none;
  transform: translateY(-50%);

  @media screen and (max-width: $mobileBreakpoint) {
    position: static;
    transform: translateY(0);
  }

  .fakeChatWindow {
    width: 330px;
    min-height: 300px;
    background-color: var(--main-background-color);
    border-style: solid;
    border-width: 0;
    border-radius: 2px;
    border-color: transparent;
    font-size: 14px;
    overflow: hidden;
    box-shadow: 7px 5px 13px 0px black;
    animation: fakeChatWindowAnimation $animationSettings;
    transition:border-radius 250ms linear,
    background-color 250ms linear;
    animation-fill-mode: forwards;

    &.styleChanged {
      border-radius: 5px;
      color: white;
      background-color: rgb(41, 28, 28);
    }
  }

  .fakeChatHeader {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: rgb(77, 23, 28);
    padding: 5px;
    font-family: system-ui, Arial, sans-serif;
    font-weight: bold;
    color: white;
    transition: background-color  250ms linear;

    &.styleChanged {
      background-color: orange;
    }
  }

  .fakeChatContent {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
  }

}
