@keyframes shadowAnimation {
  0% {
    box-shadow: 0 0 10px 10px var(--color-accent);
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    box-shadow: 0 0 30px 10px var(--color-accent);
    transform: scale(1);
  }
}

@keyframes animationInner {
  0% {
    box-shadow: inset 0 0 0 0 var(--color-accent);
    transform: scale(0);
  }

  50% {
    box-shadow: inset 0 0 10px 10px var(--main-background-color);
  }

  100% {
    box-shadow: inset 0 0 20px 20px var(--color-accent);
    transform: scale(1);
  }
}

.spinnerBackdrop {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-background-color-alpha);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 4;
}

.spinnerContainerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinnerContainer {
  border-radius: 50%;
  background-color: var(--color-accent);
  animation-name: shadowAnimation;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.spinnerInnerContainer {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  animation-name: animationInner;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  pointer-events: none;
}

.spinnerText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 600;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  user-select: none;
}
