.commonButton {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid black;
  border-radius: var(--border-radius);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 250ms linear, transform 70ms linear;
  user-select: none;

  &:hover {
    background-color: var(--color-accent);
  }

  &:active
 {
  transform: scale(0.95);
 }}

.buttonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  & + * {
    margin-left: 5px;
  }
}
