.clarify_bot_chat_popup {
  width: 330px;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  border-style: solid;
  border-color: #f0f0f0;
  border-radius: 5px;
  border-width: 0;
  font-size: 14px;
  overflow: hidden;
  box-shadow: 2px 2px 5px 1px black;
  transition: all 250ms linear;

  .closed {
    width: 30px;
    height: 25px;
  }

  .clarify_bot_header {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: var(--color-accent);
    padding: 5px;
    font-family: system-ui, Arial, sans-serif;
    font-weight: bold;
    color: white;
    transition: background-color 250ms linear;
  }

  .clarify_bot_toggle_button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 11px;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .clarify_bot_content {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 93px;
    padding: 10px;
  }

  .clarify_bot_input_wrapper {
    display: flex;
    gap: 5px;
    width: 100%;
    border-radius: 5px;
    padding: 10px 12px;
    background-color: white;
    border: 1px solid grey;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }

    input {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
    }
  }

  .clarify_bot_ask_button {
    width: fit-content;
    padding: 1px 6px;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }
}
