@import "../scss/sass_vars";

.appMain {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  position: relative;
  flex-grow: 1;
  padding: 0 5vw;
  background-image: linear-gradient(
    150deg,
    var(--main-background-color-alpha) 15%,
    var(--color-accent) 70%
    );
    perspective: 800px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

:global(.page) {
  height: 100vh;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 30px 0;

  @media screen and(max-width: $mobileBreakpoint ) {
    height: auto;
    flex-direction: column;
  }
}

:global(.pageContent) {
  height: 100%;
  width: 100%;
  overflow: visible;
}

:global(.hintText) {
  font-size: 12px;
  color: var(--main-text-color-alpha);

  &:global(.error) {
    border-radius: 3px;
    padding: 2px;
    color: var(--color-error);
    background-color: var(--main-background-color)
  }

}

:global(.inputWrapper) {
  display: flex;
  gap: 5px;
  width: 100%;
  border-radius: 5px;
  padding: 10px 12px;
  border: 1px solid grey;

  & button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
  }
  & input {
    flex-grow: 1;
    border: none;
    outline: none;
  }
}
