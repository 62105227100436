.modalForm {
  position: relative;

}

.closeBtn {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.fieldsWrapper {
  padding: 5px 0;
}

.deletePrompt {
  margin-bottom: 20px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 10px;
}
