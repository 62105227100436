
  .navList {
    width: 60%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    margin-left: auto;

    li {
      display: flex;
      justify-content: center;
      align-items: stretch;
      
      padding: 5px;
      font-size: 18px;
    }

    .activeLink {
      border-radius: var(--border-radius);
      background-color: var(--main-background-color-alpha);
    }
  }
