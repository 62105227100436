@import "../../scss/_sass_vars.scss";

.appBar {
  position: sticky;
  height: fit-content;
  top: 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 5vw;
  padding-top: 6px;
  background-color: var(--main-background-color);
  box-shadow: 1px 1px 5px 1px black;
  z-index: 2;

  @media screen and (max-width: $mobileBreakpoint) {
    height: auto;
  }
}

.notificationsWrapper {
  position: absolute;
  top: 55px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  z-index: 5;
}


.controlsWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px 0 10px 0;
  list-style: none;
}

.authWrapper {
  display: flex;
  gap: 30px;
  margin-left: auto;
  align-items: center;
}

.logo {
  display: flex;
  align-items: baseline;
}
